<template>
  <v-dialog
    v-model="dialog"
    max-width="300px"
    :persistent="load"
  >
    <v-card :disabled="load">
      <v-card-title>
        {{ p_nueva ? 'Nueva linea' : 'Editar linea' }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form">
          <v-row class="pt-8">
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="linea.nombre"
                label="Nombre"
                :rules="[rules.required]"
                validate-on-blur
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-autocomplete
                v-model="linea.rubro_codigo"
                label="Rubro"
                item-text="nombre"
                item-value="codigo"
                :rules="[rules.required]"
                :items="p_rubros"
                validate-on-blur
                outlined
                dense
                @change="get_categorias(); linea.categoria_codigo = null"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-autocomplete
                v-model="linea.categoria_codigo"
                label="Categoria"
                item-text="nombre"
                item-value="codigo"
                :rules="[rules.required]"
                :items="categorias"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal para editar o crear una linea
 * 
 *  Notas: este componente emite acciones (@nueva, @editar) al padre caundo se crea o edita una linea (en caso de estar definidas)
 *         todos los props son obligatorios
 */
import BtnConfirmar from '../util/BtnConfirmar'
import { order_list_by } from '../../util/utils'

export default {
  data () {
    return {
      load: false,
      categorias: [],
      linea: {},
      rules: {
        required: value => !!value || 'Campo requerido',
      }
    }
  },
  props: {
    value: Boolean,
    p_nueva: Boolean, // para editar una linea enviar en false, para una linea nueva en true
    p_linea: Object, /*en caso de ser nueva enviar el objeto:
                        linea = {
                              codigo: null,
                              nombre: null,
                              rubro_codigo: null,
                              categoria_codigo: null
                            }
                        */
    p_rubros: Array   // array de todos los rubros habilitados
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    dialog (val) {
      if (val) {
        if (this.p_nueva) {
          // si es nueva setea los campos en null
          this.linea = {
            codigo: null,
            nombre: null,
            rubro_codigo: this.p_linea.rubro_codigo, //Cambio para que funcione en AMB de articulos
            categoria_codigo: this.p_linea.categoria_codigo ////Cambio para que funcione en AMB de articulos
          }
        } else {
          // crea una copia del prop p_linea para editarlo
          this.linea = JSON.parse(JSON.stringify(this.p_linea))
          // trae las categorias del rubro
        }
        if(this.linea.rubro_codigo){
          this.get_categorias();
        }
      } else {
        this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        this.load = true
        if (this.p_nueva) {
          await this.$store.dispatch('lineas/nueva', this.linea)
            .then((res) => {
              this.dialog = false
              // emite la accion 'nueva' definifa en el padre
              this.$emit('nueva', res.linea)
              // muestra mensaje
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success',
              })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error',
              })
            })
        } else {
          // solo edita si realmente se modifico la linea
          if (JSON.stringify(this.linea) != JSON.stringify(this.p_linea)) {
            await this.$store.dispatch('lineas/editar', this.linea)
              .then((res) => {
                this.dialog = false
                // hace un UPPER del nombre de la linea y obtiene el nombre del rubro y la categoria
                this.linea.nombre = this.linea.nombre.toUpperCase()
                const rubro = this.p_rubros.find(rub => rub.codigo == this.linea.rubro_codigo)
                if (rubro) {
                  this.linea.rubro_nombre = rubro.nombre
                }
                const categoria = this.categorias.find(cat => cat.codigo == this.linea.categoria_codigo)
                if (categoria) {
                  this.linea.categoria_nombre = categoria.nombre
                }
                // emite la accion 'editar' definifa en el padre
                this.$emit('editar', this.linea)
                // muestra mensaje
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success',
                })
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error',
                })
              })
          } else {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: 'No se realizaron cambios en la linea: el nombre, el rubro y la categoria son iguales a los anteriores',
              color: 'warning',
              timeout: 4000
            })
          }
        }
        this.load = false
      }
    },
    async get_categorias () {
      this.$store.state.loading = true
      this.categorias = []
      const rubro = this.linea.rubro_codigo
      if (rubro) {
        await this.$store.dispatch('categorias/get_categorias', { rubro: rubro })
          .then((res) => {
            this.categorias = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
        order_list_by(this.categorias, 'nombre')
      }
      this.$store.state.loading = false
    }
  }
}
</script>